<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 566.9 195.5" style="enable-background:new 0 0 566.9 195.5;" xml:space="preserve">
    <g>
      <g>
        <path d="M437.7,22.5h44c41.7,0,58.3,29.4,58.3,73.9c0,45.3-15.8,76.6-60.4,76.6h-41.9V22.5z M412.9,195.5h70.9
          c56.2,0,83.1-40.9,83.1-96.7c0-58.8-25.8-98.9-83.1-98.9h-70.9V195.5z"/>
        <path d="M172,195.5h94c49.1,0,55.8-38.4,55.8-53.3c0-24.2-9.8-43.9-32.3-49.7V92c15.2-4.9,26.9-21.1,26.9-41.2
          C316.4,27.5,302.7,0,258,0h-86V195.5z M196.3,22.5h55.8c18.3,0,38,0,38,29.4c0,25.8-17.8,31-37.7,31h-56V22.5z M196.3,105.5h56.6
          c20.9,0,42.6,1.6,42.6,35.7c0,22-14.2,31.6-33.8,31.9h-65.3V105.5z"/>
      </g>
      <polygon points="0,0 0,22.5 58.1,22.5 58.1,195.5 83.6,195.5 83.6,22.5 141.7,22.5 141.7,0 	"/>
      <rect x="350" y="163.7" width="31.6" height="31.8"/>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'Logo',
}

</script>

<style scoped lang="scss">

</style>
