<template>
  <footer class = "footer">

    <transition name = 'fade-footer'>
      <div key='index' class = "footer-index" v-if='this.$route.name === "Index"'>
        <div class = "footer-index_filter-btn left" v-if='this.imageView'>
          {{ this.title }}
        </div>
        <div class = "footer-index_view-btn right" v-on:click='handleViewClick'>{{ this.viewText }}</div>
      </div>

      <div key='project' class = "footer-project" v-else>
        <div class = 'footer-project_title left'>{{ this.title }}, <span class = 'info-btn' v-on:click='handleProjectInfoClick'>{{ this.infoText }}</span></div>
        <div class = 'footer-project_count right'>{{ this.slideCount }} / {{ this.slideTotal }}</div>
      </div>
    </transition>

  </footer>
</template>

<script>


export default {
  name: 'Footer',
  props: {
    title: String,
    projectInfo: Boolean,
    filter: String,
    slideCount: Number,
    slideTotal: Number,
    imageView: Boolean
  },
  data() {
    return {
      showFilters: false
    }
  },
  watch: {
  },
  components: {
  },
  computed: {
    infoText: function() {
      return !this.projectInfo ? 'Read More' : 'Read Less'
    },
    viewText: function() {
      return this.imageView ? 'INDEX' : 'IMAGE'
    }
  },
  methods: {
    returnFilterClass: function(str) {
      return str === this.filter ? 'filters-nav_btn active' : 'filters-nav_btn'
    },
    handleProjectInfoClick: function() {
      !this.projectInfo ? this.$store.dispatch('showProjectInfo') : this.$store.dispatch('hideProjectInfo')
    },
    handleViewClick: function() {
      this.imageView ? this.$store.dispatch('unsetImageView') : this.$store.dispatch('setImageView')
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";


.footer {
  width: 100%;
  height: $footer-height;
  bottom: 0;
  position: fixed;
  background: white;
  border-top: solid black 1px;
  padding: 0 $margin 0 $margin;
  // temp
  z-index: 100;
  display: flex;
  align-items: center;
  @include type-16;
  &-index {
    width: 100%;
    display: flex;
  }
  &-project {
    width: 100%;
    display: flex;
  }
}

.footer .footer-project .info-btn {
  @include type-16_italic;
}

.footer .footer-index .right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin-right: $margin;
}

.footer .footer-index .left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-left: $margin;
}

.footer .footer-project .right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin-right: $margin;
}

.footer .footer-project .left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-left: $margin;
}

// ...
.fade-footer-enter {
  opacity: 0;
}

.fade-footer-enter-active {
  // 1s delay to prevent slide count mishap etc.
  transition: opacity .25s linear 1s;
}

.fade-footer-leave {
  opacity: 0;
}

.fade-footer-leave-active {
  transition: opacity .25s linear;
  opacity: 0;
}

</style>
